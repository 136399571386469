.page {
  &__fullScreen {
    height: 65vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (min-width: 800px) {
      height: calc(100vh - 579px);
      min-height: 60rem;
    }
    .content {
      flex: 1;
      padding: 5vh 0 0;
      @media (min-width: 800px) {
        padding: 15vh 0 0;
      }
    }
  }
  &__footer {
    border-bottom: 15px solid var(--c-brand-green);
    padding: 0 0 8rem 0;
    font-size: 1.2rem;
  }
}

.links {
  &__list {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 4rem 0 0;
    row-gap: 4rem;
    flex-direction: column;
    @media (min-width: 800px) {
      row-gap: 0;
      column-gap: 8rem;
      flex-direction: row;
    }
  }
  &__item {
    @media (min-width: 800px) {
      width: 50%;
      flex: 1;
    }
  }
  &__link {
    display: block;
    padding: 5rem 10rem 5rem 5rem;
    border: 1px solid var(--c-brand-green);
    font-size: clamp(2rem, 2vw, 4rem);
    line-height: 1.25;
    color: var(--c-black);
    font-weight: 300;
    text-decoration: none;
    height: 100%;
    transition: background-color 300ms;

    &:hover,
    &:focus {
      background-color: #f7f7f7;
    }
  }
}

// Hero image fix
.marquee-slide .video-bkg[ready] {
  background-size: contain;
  background-position: right;
}
