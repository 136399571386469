.l-container {
  width: 100%;
  padding: 0 10px;
  margin: 0 auto;
  padding: 0 100px;
  max-width: 1800px;

  @media (max-width: 1599px) {
    padding: 0 80px;
    max-width: 1530px;
  }
  @media (max-width: 991px) {
    padding: 0 50px;
  }
  @media (max-width: 767px) {
    padding: 0 10px;
  }
}
