// Typography

$font: "Metric Light", "Helvetica Neue", sans-serif;

:root {
  --smallBodySize: 1.2rem;
  --bodySize: 1.6rem;
  --largeBodySize: 1.8rem;
}

%headingBase {
  font-family: $font;
  font-weight: 300;
}

%h1 {
  --min-size: 4rem;
  --mid-size: 10vw;
  --max-size: 6rem;
  @extend %headingBase;

  font-size: clamp(var(--min-size), var(--mid-size), var(--max-size));
  line-height: 1.11111111;

  @media (min-width: 800px) {
    --mid-size: 6vw;
  }
}

%h2 {
  @extend %headingBase;
  font-size: 3rem;
  line-height: 1.3;
  letter-spacing: -0.01em;
}

%h3 {
  @extend %headingBase;
  font-size: 2.6rem;
  line-height: 1.12;
  letter-spacing: 0.005em;
}

%h4 {
  @extend %headingBase;
  font-size: 2.4rem;
  line-height: 1.3;
}

%h5 {
  font-size: 2rem;
  line-height: 1.2;
  font-weight: 500;
}

%bodyCopy {
  margin: 0 0 var(--space-s);
  font-family: $font;
  font-size: var(--bodySize);
}
