html {
  font-size: 62.5%;
  height: -webkit-fill-available;
}

body {
  background-color: var(--c-white);
  color: var(--c-black);
  min-height: 100vh;
  min-height: -webkit-fill-available;
  // font-family: $font;
}

h1 {
  @extend %h1;
  max-width: 25ch;

  strong {
    color: var(--c-brand-green);
    font-weight: 500;
  }

  // transform: translateY(-50%);
}

.navigation {
  opacity: 0;
  pointer-events: none;
  height: 0;
  margin-top: 13px;
  transition: opacity 1s;

  &.is-active {
    opacity: 1;
    pointer-events: all;
    height: auto;
  }
}

h2 {
  @extend %h2;
  font-weight: 500;
}
