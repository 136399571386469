@import "./page";
@import "./footer";
@import "./animatedText";
@import "./modal";

.header {
  &__title {
    color: white;
    @media (min-width: 1200px) {
      font-size: 80px !important;
      line-height: 0.75 !important;
      margin-bottom: 30px;
      span {
        font-weight: 300;
        display: block;
        font-family: "Metric Light", Arial, sans-serif;
      }
    }
  }
}

.marquee-slide {
  .content-wrapper {
    width: 60%;
  }
  .desc {
    width: 75%;
  }
}
.dashed-text-purple:after {
  background: #7630ea;
}

.episodes {
  background-color: black;
  border-top: 2px solid white;
  padding: 60px 0 30px 0;
  @media (min-width: 1200px) {
    padding: 100px 0 70px;
  }
  &__grid {
    display: grid;
    gap: 15px;
    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @media (min-width: 1200px) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
  }
  &__card {
    color: white;
    display: block;
    margin-bottom: 30px;

    &.js-modal-open {
      cursor: pointer;
    }

    > * {
      pointer-events: none;
    }
  }
  &__image-wrap {
    position: relative;
    margin-bottom: 10px;
    border-radius: 8px;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
    }

    &--coming-soon {
      &::before,
      &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      &::before {
        content: "";
        display: block;
        background-color: rgba(220, 220, 220, 0.6);
      }
      &::after {
        content: "coming soon";
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        font-family: "Metric SemiBold", sans-serif;
        font-weight: 600;
        font-size: 20px;
        color: #fff;
      }
    }
  }
  &__thumb {
    width: 100%;
  }
  &__heading {
    text-transform: uppercase;
    font-size: 20px;
    line-height: 1.1;
  }
  &__content {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    span {
      font-size: 15px;
      line-height: 1.16667;
      opacity: 0.7;
    }
  }
  &__text {
    width: 85%;
    margin: 0;
    font-size: 16px;
    line-height: 1.16666667;
    opacity: 0.8;
    font-family: "Metric Light", Arial, sans-serif;
  }
}

.cta-block {
  padding: 0 0 60px 0;
  @media (min-width: 1200px) {
    padding: 40px 0 100px;
  }
  &__image-wrap {
    margin-top: 17px;
  }
  &__image {
    width: 100%;
    display: block;
  }
  &__heading {
    margin-top: revert;
    @media (min-width: 1200px) {
      margin-top: 20px;
    }
  }
  &__text {
    margin: 20px 0 30px 0;
  }
}

.accordion {
  &__image {
    grid-column: 1 / span 4;
    figure {
      margin: 0 0 50px;
    }
  }
  &__content {
    grid-column: 5 / -1;
  }
  &__contentHeading {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
  }
  &__contentSubHeading {
    font-size: 24px;
    margin-bottom: 2.6875rem;
  }
  &__list {
    list-style: none;
    padding: 0;
    margin: 70px 0 50px;
  }
  &__item {
    padding: 30px 0;
    border-bottom: 1px solid #707070;
    @media (min-width: 800px) {
      padding: 30px 0;
    }

    &:first-child {
      border-top: 1px solid #707070;
    }

    button {
      appearance: none;
      background-color: transparent;
      font-size: 24px;
      line-height: 1.25;
      border: 0;
      width: 100%;
      text-align: left;
      position: relative;
      padding: 60px 40px 0 0;
      font-family: "HPEMetricSemiBold";
      text-transform: uppercase;
      @media (min-width: 800px) {
        font-size: 30px;
        padding: 20px 56px 20px 104px;
      }

      &::before {
        position: absolute;
        left: 0;
        top: 0;
        content: attr(data-number);
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #01a982;
        color: #fff;
        font-size: 20px;
        line-height: 1;
        font-weight: 600;
        font-family: "HPEMetricSemiBold";
        border-radius: 50%;
        width: 40px;
        height: 40px;
        margin-bottom: 20px;
        @media (min-width: 800px) {
          top: 50%;
          transform: translateY(-50%);
          width: 74px;
          height: 74px;
          font-size: 37px;
        }
      }

      &::after {
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='57.256' height='57.256' viewBox='0 0 57.256 57.256'%3E%3Cpath id='Path_272' data-name='Path 272' d='M0,0H36.486V36.486' transform='translate(25.8 2.828) rotate(45)' fill='none' stroke='%2301a982' stroke-width='8'/%3E%3C/svg%3E%0A");
        background-size: contain;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        display: block;
        transition: transform 200ms ease-in-out;
        @media (min-width: 800px) {
          width: 36px;
          height: 36px;
        }
      }

      @media (min-width: 800px) {
        display: flex;
        text-align: left;
        gap: 40px;
        align-items: center;
        font-size: 32px;
      }
      &:hover,
      &:focus {
        background-color: transparent;
        color: #000;
        box-shadow: none;
      }

      &[aria-expanded="true"] {
        &::after {
          transform: translateY(-50%) rotate(90deg);
        }
      }
    }
  }
  &__title {
    flex: 1;
    text-align: left;
    font-size: 20px;
    text-transform: uppercase;
    @media (min-width: 800px) {
      font-size: 40px;
      margin-left: 15%;
    }
  }
  &__number {
  }
  &__toggle {
    margin: 0;
  }
  &__panel {
    max-height: 1000px;
    overflow: hidden;
    transition: max-height 200ms ease-in-out;
    &[aria-hidden="true"] {
      max-height: 0;
    }
  }
  &__panelContent {
    padding: 20px 0;
    @media (min-width: 800px) {
      // padding: 40px 0 0;
      margin-left: 104px;
    }
  }
  &__panelButton {
    margin-top: 20px;
  }
}
