:root {
  --c-brand-green: #05a982;
  --c-white: #fff;
  --c-black: #000;
  --smallBodySize: 1.2rem;
  --bodySize: 1.6rem;
  --largeBodySize: 1.8rem;
}

h2, h1 {
  font-family: Metric Light, Helvetica Neue, sans-serif;
  font-weight: 300;
}

h1 {
  --min-size: 4rem;
  --mid-size: 10vw;
  --max-size: 6rem;
  font-size: clamp(var(--min-size), var(--mid-size), var(--max-size) );
  line-height: 1.11111;
}

@media (min-width: 800px) {
  h1 {
    --mid-size: 6vw;
  }
}

h2 {
  letter-spacing: -.01em;
  font-size: 3rem;
  line-height: 1.3;
}

*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html, body {
  height: 100%;
}

img, picture, video, canvas, svg {
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

html {
  height: -webkit-fill-available;
  font-size: 62.5%;
}

body {
  background-color: var(--c-white);
  color: var(--c-black);
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

h1 {
  max-width: 25ch;
}

h1 strong {
  color: var(--c-brand-green);
  font-weight: 500;
}

.navigation {
  opacity: 0;
  pointer-events: none;
  height: 0;
  margin-top: 13px;
  transition: opacity 1s;
}

.navigation.is-active {
  opacity: 1;
  pointer-events: all;
  height: auto;
}

h2 {
  font-weight: 500;
}

.l-container {
  width: 100%;
  max-width: 1800px;
  margin: 0 auto;
  padding: 0 100px;
}

@media (max-width: 1599px) {
  .l-container {
    max-width: 1530px;
    padding: 0 80px;
  }
}

@media (max-width: 991px) {
  .l-container {
    padding: 0 50px;
  }
}

@media (max-width: 767px) {
  .l-container {
    padding: 0 10px;
  }
}

.page__fullScreen {
  height: 65vh;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

@media (min-width: 800px) {
  .page__fullScreen {
    height: calc(100vh - 579px);
    min-height: 60rem;
  }
}

.page__fullScreen .content {
  flex: 1;
  padding: 5vh 0 0;
}

@media (min-width: 800px) {
  .page__fullScreen .content {
    padding: 15vh 0 0;
  }
}

.page__footer {
  border-bottom: 15px solid var(--c-brand-green);
  padding: 0 0 8rem;
  font-size: 1.2rem;
}

.links__list {
  flex-direction: column;
  row-gap: 4rem;
  margin: 4rem 0 0;
  padding: 0;
  list-style: none;
  display: flex;
}

@media (min-width: 800px) {
  .links__list {
    flex-direction: row;
    gap: 0 8rem;
  }
}

@media (min-width: 800px) {
  .links__item {
    width: 50%;
    flex: 1;
  }
}

.links__link {
  border: 1px solid var(--c-brand-green);
  color: var(--c-black);
  height: 100%;
  padding: 5rem 10rem 5rem 5rem;
  font-size: clamp(2rem, 2vw, 4rem);
  font-weight: 300;
  line-height: 1.25;
  text-decoration: none;
  transition: background-color .3s;
  display: block;
}

.links__link:hover, .links__link:focus {
  background-color: #f7f7f7;
}

.marquee-slide .video-bkg[ready] {
  background-position: 100%;
  background-size: contain;
}

.footer__legal {
  max-width: 80ch;
}

.footer__logos {
  justify-content: flex-end;
  align-items: center;
  margin-top: 4rem;
  display: flex;
}

.footer__logoHeader {
  font-size: 2rem;
  font-style: italic;
  font-weight: 300;
}

@media (min-width: 800px) {
  .footer__logoHeader {
    font-size: 3rem;
  }
}

.footer__logoList {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.footer__logoList li {
  justify-content: center;
  align-items: center;
  padding: 0 3.5rem;
  display: flex;
}

.footer__logoList li:last-child {
  border-left: 1px solid var(--c-black);
  padding-right: 0;
}

.footer__logo {
  height: auto;
}

.footer__logo--hpe {
  width: 5.5rem;
}

.footer__logo--nvidia {
  width: 6.5rem;
}

@media (min-width: 800px) {
  .footer__logo--hpe {
    width: 10.5rem;
  }

  .footer__logo--nvidia {
    width: 11.5rem;
  }
}

.dm-modal {
  width: 100%;
  height: 100%;
  z-index: 200;
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.dm-modal.active {
  display: flex;
}

.dm-modal .dm-modal-inner {
  width: 70%;
  height: 0;
  padding-top: 39.3%;
  position: relative;
}

.dm-modal iframe {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.dm-modal .dm-modal-close {
  width: 60px;
  height: 60px;
  appearance: none;
  text-indent: -100000000px;
  background: none;
  border: 0;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
}

.dm-modal .dm-modal-close svg {
  margin: 0 auto;
}

.header__title {
  color: #fff;
}

@media (min-width: 1200px) {
  .header__title {
    margin-bottom: 30px;
    font-size: 80px !important;
    line-height: .75 !important;
  }

  .header__title span {
    font-family: Metric Light, Arial, sans-serif;
    font-weight: 300;
    display: block;
  }
}

.marquee-slide .content-wrapper {
  width: 60%;
}

.marquee-slide .desc {
  width: 75%;
}

.dashed-text-purple:after {
  background: #7630ea;
}

.episodes {
  background-color: #000;
  border-top: 2px solid #fff;
  padding: 60px 0 30px;
}

@media (min-width: 1200px) {
  .episodes {
    padding: 100px 0 70px;
  }
}

.episodes__grid {
  gap: 15px;
  display: grid;
}

@media (min-width: 768px) {
  .episodes__grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (min-width: 1200px) {
  .episodes__grid {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}

.episodes__card {
  color: #fff;
  margin-bottom: 30px;
  display: block;
}

.episodes__card.js-modal-open {
  cursor: pointer;
}

.episodes__card > * {
  pointer-events: none;
}

.episodes__image-wrap {
  border-radius: 8px;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
}

.episodes__image-wrap img {
  width: 100%;
  display: block;
}

.episodes__image-wrap--coming-soon:before, .episodes__image-wrap--coming-soon:after {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.episodes__image-wrap--coming-soon:before {
  content: "";
  background-color: #dcdcdc99;
  display: block;
}

.episodes__image-wrap--coming-soon:after {
  content: "coming soon";
  text-transform: uppercase;
  color: #fff;
  justify-content: center;
  align-items: center;
  font-family: Metric SemiBold, sans-serif;
  font-size: 20px;
  font-weight: 600;
  display: flex;
}

.episodes__thumb {
  width: 100%;
}

.episodes__heading {
  text-transform: uppercase;
  font-size: 20px;
  line-height: 1.1;
}

.episodes__content {
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}

.episodes__content span {
  opacity: .7;
  font-size: 15px;
  line-height: 1.16667;
}

.episodes__text {
  width: 85%;
  opacity: .8;
  margin: 0;
  font-family: Metric Light, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.16667;
}

.cta-block {
  padding: 0 0 60px;
}

@media (min-width: 1200px) {
  .cta-block {
    padding: 40px 0 100px;
  }
}

.cta-block__image-wrap {
  margin-top: 17px;
}

.cta-block__image {
  width: 100%;
  display: block;
}

.cta-block__heading {
  margin-top: revert;
}

@media (min-width: 1200px) {
  .cta-block__heading {
    margin-top: 20px;
  }
}

.cta-block__text {
  margin: 20px 0 30px;
}

.accordion__image {
  grid-column: 1 / span 4;
}

.accordion__image figure {
  margin: 0 0 50px;
}

.accordion__content {
  grid-column: 5 / -1;
}

.accordion__contentHeading {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
}

.accordion__contentSubHeading {
  margin-bottom: 2.6875rem;
  font-size: 24px;
}

.accordion__list {
  margin: 70px 0 50px;
  padding: 0;
  list-style: none;
}

.accordion__item {
  border-bottom: 1px solid #707070;
  padding: 30px 0;
}

@media (min-width: 800px) {
  .accordion__item {
    padding: 30px 0;
  }
}

.accordion__item:first-child {
  border-top: 1px solid #707070;
}

.accordion__item button {
  appearance: none;
  width: 100%;
  text-align: left;
  text-transform: uppercase;
  background-color: #0000;
  border: 0;
  padding: 60px 40px 0 0;
  font-family: HPEMetricSemiBold;
  font-size: 24px;
  line-height: 1.25;
  position: relative;
}

@media (min-width: 800px) {
  .accordion__item button {
    padding: 20px 56px 20px 104px;
    font-size: 30px;
  }
}

.accordion__item button:before {
  content: attr(data-number);
  color: #fff;
  width: 40px;
  height: 40px;
  background-color: #01a982;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  font-family: HPEMetricSemiBold;
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: 800px) {
  .accordion__item button:before {
    width: 74px;
    height: 74px;
    font-size: 37px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.accordion__item button:after {
  content: "";
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='57.256' height='57.256' viewBox='0 0 57.256 57.256'%3E%3Cpath id='Path_272' data-name='Path 272' d='M0,0H36.486V36.486' transform='translate(25.8 2.828) rotate(45)' fill='none' stroke='%2301a982' stroke-width='8'/%3E%3C/svg%3E%0A");
  background-size: contain;
  transition: transform .2s ease-in-out;
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

@media (min-width: 800px) {
  .accordion__item button:after {
    width: 36px;
    height: 36px;
  }
}

@media (min-width: 800px) {
  .accordion__item button {
    text-align: left;
    align-items: center;
    gap: 40px;
    font-size: 32px;
    display: flex;
  }
}

.accordion__item button:hover, .accordion__item button:focus {
  color: #000;
  box-shadow: none;
  background-color: #0000;
}

.accordion__item button[aria-expanded="true"]:after {
  transform: translateY(-50%)rotate(90deg);
}

.accordion__title {
  text-align: left;
  text-transform: uppercase;
  flex: 1;
  font-size: 20px;
}

@media (min-width: 800px) {
  .accordion__title {
    margin-left: 15%;
    font-size: 40px;
  }
}

.accordion__toggle {
  margin: 0;
}

.accordion__panel {
  max-height: 1000px;
  transition: max-height .2s ease-in-out;
  overflow: hidden;
}

.accordion__panel[aria-hidden="true"] {
  max-height: 0;
}

.accordion__panelContent {
  padding: 20px 0;
}

@media (min-width: 800px) {
  .accordion__panelContent {
    margin-left: 104px;
  }
}

.accordion__panelButton {
  margin-top: 20px;
}

.u-flex {
  display: flex;
}

.u-justify-between {
  justify-content: space-between;
}

.u-items-center {
  align-items: center;
}

/*# sourceMappingURL=styles.css.map */
