.dm-modal {
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 200;

  &.active {
    display: flex;
  }

  .dm-modal-inner {
    width: 70%;
    height: 0;
    padding-top: 39.3%;
    position: relative;
  }

  iframe {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }

  .dm-modal-close {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 60px;
    height: 60px;
    appearance: none;
    border: 0;
    padding: 0;
    text-indent: -99999999px;
    background: transparent;
    svg {
      margin: 0 auto;
    }
  }
}
