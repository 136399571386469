.footer {
  &__legal {
    max-width: 80ch;
  }
  &__logos {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 4rem;
  }
  &__logoHeader {
    font-size: 2rem;
    font-style: italic;
    font-weight: 300;
    @media (min-width: 800px) {
      font-size: 3rem;
    }
  }
  &__logoList {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    li {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 3.5rem;

      &:last-child {
        border-left: 1px solid var(--c-black);
        padding-right: 0;
      }
    }
  }

  &__logo {
    height: auto;
    &--hpe {
      width: 5.5rem;
    }
    &--nvidia {
      width: 6.5rem;
    }
    @media (min-width: 800px) {
      &--hpe {
        width: 10.5rem;
      }
      &--nvidia {
        width: 11.5rem;
      }
    }
  }
}
